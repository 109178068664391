import React from 'react'
 export default function House() {
 return (
 <>
 <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path strokeLinejoin="round" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1" stroke="#221b38" fill="none" d="M16 2.5V6.37L19 8.89V2.5C19 2.22 18.78 2 18.5 2H16.5C16.22 2 16 2.22 16 2.5Z" clipRule="evenodd" fillRule="evenodd"></path>
<path strokeLinejoin="round" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1" stroke="#221b38" fill="none" d="M19 8.89L12 3L5 8.89V21C5 21.55 5.45 22 6 22H18C18.55 22 19 21.55 19 21V8.89Z" clipRule="evenodd" fillRule="evenodd"></path>
<path strokeLinejoin="round" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1" stroke="#221b38" d="M21.5 11L12 3L2.5 11"></path>
</svg>
</>
)}