import React from 'react'
 export default function Menu3Filled() {
 return (
 <>
 <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill="black" d="M1 6C1 5.44772 1.44772 5 2 5H22C22.5523 5 23 5.44772 23 6C23 6.55228 22.5523 7 22 7H2C1.44772 7 1 6.55228 1 6Z" clipRule="evenodd" fillRule="evenodd"></path>
<path fill="black" d="M1 12C1 11.4477 1.44772 11 2 11H16C16.5523 11 17 11.4477 17 12C17 12.5523 16.5523 13 16 13H2C1.44772 13 1 12.5523 1 12Z" clipRule="evenodd" fillRule="evenodd"></path>
<path fill="black" d="M1 18C1 17.4477 1.44772 17 2 17H8C8.55228 17 9 17.4477 9 18C9 18.5523 8.55228 19 8 19H2C1.44772 19 1 18.5523 1 18Z" clipRule="evenodd" fillRule="evenodd"></path>
</svg>
</>
)}